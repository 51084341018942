
  import { defineComponent } from 'vue'
  import TrackList from '@/shared/components/track/TrackList.vue'
  import EditModal from '@/shared/components/EditModal.vue'
  import { usePlaylistStore } from '@/library/playlist/store'
  import { useShareStore } from '@/library/share/store'
  import ShareModal from '@/library/share/ShareModal.vue'
  import { Track } from '@/shared/api'
  import { useMainStore } from '@/shared/store'
  import PlaylistModal from '@/library/playlist/PlaylistModal.vue'

  export default defineComponent({
    components: {
      TrackList,
      EditModal,
      ShareModal,
      PlaylistModal,
    },
    props: {
      id: { type: String, required: true }
    },
    setup() {
      return {
        playlistStore: usePlaylistStore(),
        shareStore: useShareStore(),
        mainStore: useMainStore(),
      }
    },
    data() {
      return {
        playlist: null as any,
        showEditModal: false,
        error: null as any,
        showShare: false,
        showPlaylist: false,
      }
    },
    computed: {
      isRandom(): boolean {
        return this.id === 'random'
      },
      isPlaying(): boolean {
        return this.$store.getters['player/isPlaying']
      },
      playableTracks(): Track[] {
        return (this.playlist?.tracks || [])
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(value: string) {
          this.playlist = null
          this.$api.getPlaylist(value)
            .then(playlist => {
              this.playlist = playlist
              this.error = null
            })
            .catch(e => {
              this.error = e
            })
        }
      }
    },
    methods: {
      async playNow() {
        return this.$store.dispatch('player/playNow', {
          tracks: this.playableTracks,
        })
      },
      async shuffleNow() {
        return this.$store.dispatch('player/shuffleNow', {
          tracks: this.playableTracks,
        })
      },
      removeTrack(index: number) {
        this.playlist.tracks.splice(index, 1)
        return this.playlistStore.removeTrack(this.id, index)
      },
      updatePlaylist(value: any) {
        this.playlist = value
        return this.playlistStore.update(this.playlist)
      },
      deletePlaylist() {
        return this.playlistStore.delete(this.id).then(() => {
          this.$router.replace({ name: 'playlists' })
        })
      },
    }
  })
