
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useShareStore } from '@/library/share/store'
  import { Share, Track } from '@/shared/api'
  import { BFormDatepicker } from 'bootstrap-vue'
  import { useMainStore } from '@/shared/store'

  export default defineComponent({
    components: {
      BFormDatepicker
    },
    props: {
      visible: { type: Boolean, required: true },
      tracks: { type: Array<Track>, required: true },
    },
    setup() {
      const shareStore = useShareStore()
      const { shares, supported } = storeToRefs(shareStore)
      return {
        shareStore,
        shares,
        supported,
      }
    },
    data() {
      return {
        type: 'select',
        item: {} as Share,
      }
    },
    async created() {
      await this.shareStore.load()
    },
    methods: {
      cancel() {
        this.$emit('update:visible', false)
        this.item = {} as Share
        this.type = 'select'
      },
      async save(shareId = '') {
        if (shareId) {
          await this.shareStore.addTracks(shareId, this.tracks.map(t => t.id))
          this.type = 'url'
          this.item = await this.shareStore.get(shareId) || {} as Share
        } else {
          this.item = await this.shareStore.create({ ...this.item, expires: this.item.expires?.valueOf(), id: this.tracks.map(t => t.id) })
          this.type = 'url'
        }
      },
      async copyToClipboard(url: string) {
        try {
          await navigator.clipboard.writeText(url)
          useMainStore().addNotification({ message: 'Successfully link copied to clipboard', title: 'Share' })
        } catch (e) {
          useMainStore().addNotification({ message: 'Failed link copy to clipboard', title: 'Share', type: 'danger' })
        }
      }
    }
  })
