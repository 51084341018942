
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/shared/store'
  import { BToast } from 'bootstrap-vue'

  export default defineComponent({
    components: {
      BToast,
    },
    setup() {
      const mainStore = useMainStore()
      const { notifications } = storeToRefs(mainStore)
      return {
        notifications,
        removeNotification: mainStore.removeNotification,
      }
    },
  })
