
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import TrackList from '@/shared/components/track/TrackList.vue'
  import EditModal from '@/shared/components/EditModal.vue'
  import { useShareStore } from '@/library/share/store'
  import { Share, Track } from '@/shared/api'
  import { useMainStore } from '@/shared/store'
  import { BFormDatepicker } from 'bootstrap-vue'

  export default defineComponent({
    components: {
      TrackList,
      EditModal,
      BFormDatepicker,
    },
    props: {
      id: { type: String, required: true }
    },
    setup() {
      const shareStore = useShareStore()
      const { supported } = storeToRefs(shareStore)
      return {
        supported,
        shareStore,
        mainStore: useMainStore(),
      }
    },
    data() {
      return {
        share: undefined as undefined | Share,
        showEditModal: false,
      }
    },
    computed: {
      isRandom(): boolean {
        return this.id === 'random'
      },
      isPlaying(): boolean {
        return this.$store.getters['player/isPlaying']
      },
      playableTracks(): Track[] {
        return (this.share?.tracks || [])
      }
    },
    watch: {
      id: {
        immediate: true,
        async handler(value: string) {
          this.share = await this.shareStore.get(value)
        }
      }
    },
    methods: {
      async playNow() {
        return this.$store.dispatch('player/playNow', {
          tracks: this.playableTracks,
        })
      },
      async shuffleNow() {
        return this.$store.dispatch('player/shuffleNow', {
          tracks: this.playableTracks,
        })
      },
      async removeTrack(id: string) {
        await this.shareStore.removeTrack(this.id, id)
        this.share = await this.shareStore.get(this.id)
      },
      async updateShare(s: Share) {
        await this.shareStore.update({ id: s.id, description: s.description, expires: s.expires?.valueOf(), secret: s.secret, download: s.download })
        this.share = await this.shareStore.get(s.id)
      },
      deleteShare() {
        return this.shareStore.delete(this.id).then(() => {
          this.$router.replace({ name: 'shares' })
        })
      },
      async copyToClipboard(url: string) {
        try {
          await navigator.clipboard.writeText(url)
          useMainStore().addNotification({ message: 'Successfully link copied to clipboard', title: 'Share' })
        } catch (e) {
          useMainStore().addNotification({ message: 'Failed link copy to clipboard', title: 'Share', type: 'danger' })
        }
      },
      async open(url: string) {
        window?.open(url, '_blank')?.focus()
      }
    }
  })
