
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import ContentLoader from '@/shared/components/ContentLoader.vue'
  import EditModal from '@/shared/components/EditModal.vue'
  import TrackList from '@/shared/components/track/TrackList.vue'
  import { BFormInput } from 'bootstrap-vue'

  import { useRadioStore } from '@/library/radio/store'
  import { useMainStore } from '@/shared/store'

  export default defineComponent({
    components: {
      TrackList,
      ContentLoader,
      EditModal,
      BFormInput,
    },
    setup() {
      const radioStore = useRadioStore()
      const { setError } = useMainStore()
      const { stations, supported } = storeToRefs(radioStore)
      return { stations, supported, setError, radioStore }
    },
    data() {
      return {
        showEditModal: false,
        editItem: {},
      }
    },
    async created() {
      this.radioStore.load()
    },
    methods: {
      editRadioStation(index: number) {
        this.editItem = this.stations && this.stations[index] ? this.stations[index] : {}
        this.showEditModal = true
      },
      deleteRadioStation(index: number) {
        if (this.stations) {
          this.radioStore.delete(this.stations[index].id)
        }
      },
      updateStation(value: any) {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

        if (!value.title) {
          this.setError({ name: 'Radio Station Name', message: 'Radio Station Name is required' })
          return
        }
        if (!value.url) {
          this.setError({ name: 'Radio Station Stream URL', message: 'Radio Station Stream URL is required' })
          return
        }
        if (!pattern.test(value.url)) {
          this.setError({ name: 'Radio Station Stream URL', message: 'Radio Station Stream URL is not valid' })
          return
        }
        if (!!value.homepageUrl && !pattern.test(value.homepageUrl)) {
          this.setError({ name: 'Radio Station Home Page URL', message: 'Radio Station Home Page URL is not valid' })
          return
        }

        if (value?.id) {
          this.radioStore.update(value)
          this.editItem = {}
          return
        }

        this.radioStore.create(value?.title, value?.url, value?.homeURL || '')
        this.editItem = {}
      },
    },
  })
