
  import { defineComponent } from 'vue'
  import TrackList from '@/shared/components/track/TrackList.vue'
  import { Track } from '@/shared/api'
  import { config } from '@/shared/config'
  import Logo from '@/app/Logo.vue'
  import { BOverlay } from 'bootstrap-vue'
  import { toQueryString } from '@/shared/utils'

  export default defineComponent({
    components: {
      TrackList,
      Logo,
      BOverlay
    },
    props: {
      id: { type: String, required: true },
      srv: { type: String, default: '' },
    },
    data() {
      return {
        authLoad: false,

        secretReqired: false,
        secret: window.sessionStorage.getItem(`xonic-share-${this.id}-${this.srv}`) || '',

        error: '',
        share: null as any,
      }
    },

    computed: {
      isRandom(): boolean {
        return this.id === 'random'
      },
      isPlaying(): boolean {
        return this.$store.getters['player/isPlaying']
      },
      playableTracks(): Track[] {
        return (this.share?.tracks || [])
      },
      getSrv() {
        try {
          const url = new URL(this.srv || config.serverUrl)
          if (url.protocol !== 'http:' && url.protocol !== 'https:') return null
          return this.srv || config.serverUrl
        } catch (e) {
          return null
        }
      },
    },
    watch: {
      id: {
        async handler() {
          await this.load()
        }
      },
      srv: {
        async handler() {
          await this.load()
        }
      },
    },
    async created() {
      this.$store.commit('player/setQueue', [])
      await this.load()
    },
    methods: {
      async load() {
        if (!this.getSrv) {
          this.error = 'Not valid server url'
          return
        }

        try {
          this.error = ''
          this.$api.setAuthToShare(this.getSrv, toQueryString({ shareId: this.id, ...(this.secret ? { secret: this.secret } : {}) }))
          const share = await this.$api.getSharePublic(this.id, this.secret)
          if (!share) throw new Error('')

          this.share = share
          if (share.tracks) {
            this.$store.dispatch('player/addToQueue', share.tracks)
            this.$store.dispatch('player/resetQueue')
          }
          window.sessionStorage.setItem(`xonic-share-${this.id}-${this.getSrv}`, this.secret)
        } catch (e) {
          console.log(e)
          if (`${e}`.includes('auth required')) {
            if (!this.secretReqired) {
              this.secretReqired = true
            } else {
              this.error = 'Wrong secret'
            }
            return
          }
          this.error = `${e}`
        }
      },
      async playNow() {
        return this.$store.dispatch('player/playNow', {
          tracks: this.playableTracks,
        })
      },
      async shuffleNow() {
        return this.$store.dispatch('player/shuffleNow', {
          tracks: this.playableTracks,
        })
      },
      downloadTrack(item: Track) {
        window.location.href = this.$api.getDownloadUrl(item.id)
      },
    }
  })
