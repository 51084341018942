
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import EditModal from '@/shared/components/EditModal.vue'
  import { useShareStore } from '@/library/share/store'
  import BaseTable from '@/shared/components/BaseTable.vue'
  import BaseTableHead from '@/shared/components/BaseTableHead.vue'
  import CellMenu from '@/shared/components/CellMenu.vue'
  import { Share } from '@/shared/api'
  import { BFormDatepicker } from 'bootstrap-vue'
  import { useMainStore } from '@/shared/store'

  export default defineComponent({
    components: {
      BaseTable,
      BaseTableHead,
      CellMenu,
      EditModal,
      BFormDatepicker,
    },
    setup() {
      const storeShare = useShareStore()
      const { supported, shares } = storeToRefs(storeShare)
      return { supported, shares, storeShare }
    },
    data() {
      return {
        showEditModal: false,
        editItem: null as null | Share
      }
    },
    async created() {
      await this.storeShare.load(true)
    },
    methods: {
      edit(item: Share) {
        this.editItem = item
        this.showEditModal = true
      },
      updateShare(s: Share) {
        this.storeShare.update({ id: s.id, description: s.description, expires: s.expires?.valueOf(), secret: s.secret, download: s.download })
      },
      async deleteShare(item: Share) {
        await this.storeShare.delete(item.id)
      },
      async copyToClipboard(url: string) {
        try {
          await navigator.clipboard.writeText(url)
          useMainStore().addNotification({ message: 'Successfully link copied to clipboard', title: 'Share' })
        } catch (e) {
          useMainStore().addNotification({ message: 'Failed link copy to clipboard', title: 'Share', type: 'danger' })
        }
      },
      async open(url: string) {
        window?.open(url, '_blank')?.focus()
      }
    }
  })
