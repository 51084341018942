
  import { defineComponent } from 'vue'
  import {
    BListGroup,
    BListGroupItem,
  } from 'bootstrap-vue'

  export default defineComponent({
    components: {
      BListGroup,
      BListGroupItem,
    },
    props: {
      options: { type: Array, required: true },
      list: { type: Array, required: true },
      save: { type: Function, required: true }
    },
    methods: {
      moveUp(opt: string) {
        this.$emit('list', this.list.filter(i => i === opt))
      },
      moveDown(opt: string) {
        this.$emit('list', this.list.filter(i => i === opt))
      },
      toggleOption(opt: string) {
        if (this.list.includes(opt)) this.save(this.list.filter(i => i !== opt))
        else this.save([...this.list, opt])
      },
    }
  })
