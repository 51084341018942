
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import { usePodcastStore } from '@/library/podcast/store'
  import { useShareStore } from '@/library/share/store'
  import CellTrackNumber from '@/shared/components/track/CellTrackNumber.vue'
  import CellActions from '@/shared/components/track/CellActions.vue'
  import CellDuration from '@/shared/components/track/CellDuration.vue'
  import CellTitle from '@/shared/components/track/CellTitle.vue'
  import OverflowFade from '@/shared/components/OverflowFade.vue'
  import { Podcast, Track, UnsupportedOperationError } from '@/shared/api'
  import BaseTable from '@/shared/components/BaseTable.vue'
  import BaseTableHead from '@/shared/components/BaseTableHead.vue'
  import ShareModal from '@/library/share/ShareModal.vue'

  export default defineComponent({
    components: {
      BaseTableHead,
      BaseTable,
      CellTitle,
      CellDuration,
      CellActions,
      CellTrackNumber,
      OverflowFade,
      ShareModal,
    },
    props: {
      id: { type: String, required: true },
    },
    setup() {
      const podcastStore = usePodcastStore()
      const { supported } = storeToRefs(podcastStore)
      return { supported, podcastStore, shareStore: useShareStore() }
    },
    data() {
      return {
        loading: false,
        hasMore: true,
        tracksEnd: 25,
        traksStep: 25,
        showAddModal: false,
        unsupported: false,
        showShare: false,
      }
    },
    computed: {
      podcast(): null | Podcast {
        return this.podcastStore.getPodcast(this.id) || null
      },
      isPlaying(): boolean {
        return this.$store.getters['player/isPlaying']
      },
      playingTrackId(): any {
        return this.$store.getters['player/trackId']
      },
      playableTracks(): Track[] {
        return this.podcast?.tracks?.filter((x: any) => !x.isUnavailable) || []
      }
    },
    async created() {
      try {
        await this.podcastStore.load()
      } catch (err) {
        if (err instanceof UnsupportedOperationError) {
          this.unsupported = true
          return
        }
        throw err
      }
    },
    methods: {
      async loadMore() {
        this.loading = true
        const tracks = this.podcast?.tracks || []
        const tracksLen = tracks?.length || 0
        this.tracksEnd = this.tracksEnd + this.traksStep < tracksLen ? this.tracksEnd + this.traksStep : tracksLen
        this.hasMore = tracksLen > this.tracksEnd
        setTimeout(() => { this.loading = false }, 500)
      },
      async playNow() {
        return this.$store.dispatch('player/playNow', {
          tracks: this.playableTracks,
        })
      },
      async shuffleNow() {
        return this.$store.dispatch('player/shuffleNow', {
          tracks: this.playableTracks,
        })
      },
      async playTrack(track: any) {
        if (track.isUnavailable) {
          return
        }
        if (track.id === this.playingTrackId) {
          return this.$store.dispatch('player/playPause')
        }
        const index = this.playableTracks.findIndex((x: any) => x.id === track.id)
        return this.$store.dispatch('player/playTrackList', {
          index,
          tracks: this.playableTracks,
        })
      },
      async deletePodcast() {
        await this.podcastStore.delete(this.id)
        return this.$router.replace({ name: 'podcasts' })
      },
      async downloadEpisode(item: any, reDownload: boolean) {
        if (reDownload) {
          await this.podcastStore.redownloadEpisode(this.id, item.id)
          return
        }
        await this.podcastStore.downloadEpisode(this.id, item.id)
      },
      async deleteEpisode(item: any) {
        await this.podcastStore.deleteEpisode(this.id, item.id)
      },
    }
  })
