
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useFilesStore } from '@/library/files/store'
  import { useMainStore } from '@/shared/store'
  import { useShareStore } from '@/library/share/store'
  import { Track } from '@/shared/api'
  import BaseTable from '@/shared/components/BaseTable.vue'
  import BaseTableHead from '@/shared/components/BaseTableHead.vue'
  import CellTrackNumber from '@/shared/components/track/CellTrackNumber.vue'
  import CellTitle from '@/shared/components/track/CellTitle.vue'
  import CellActions from '@/shared/components/track/CellActions.vue'
  import PlaylistModal from '@/library/playlist/PlaylistModal.vue'
  import ShareModal from '@/library/share/ShareModal.vue'

  export default defineComponent({
    components: {
      BaseTable,
      BaseTableHead,
      CellTrackNumber,
      CellTitle,
      CellActions,
      ShareModal,
      PlaylistModal,
    },
    props: {
      pathID: { type: String, default: '' }
    },
    setup() {
      const filesStore = useFilesStore()
      const { supported, files, pathString } = storeToRefs(filesStore)
      return { supported, files, pathString, filesStore, mainStore: useMainStore(), shareStore: useShareStore() }
    },
    data() {
      return {
        showShare: false,
        showPlaylist: false,
      }
    },
    computed: {
      isPlaying(): boolean {
        return this.$store.getters['player/isPlaying']
      },
      playingTrackId(): any {
        return this.$store.getters['player/trackId']
      },
      playableTracks(): Track[] {
        return (this.files?.files || [])
      },
      path() {
        return this.pathString.split('/')
      }
    },
    watch: {
      pathID: {
        immediate: true,
        handler(value: string) {
          this.filesStore.load(value === '' ? '' : '/' + value)
        }
      }
    },
    methods: {
      async playNow() {
        return this.$store.dispatch('player/playNow', {
          tracks: this.playableTracks,
        })
      },
      async shuffleNow() {
        return this.$store.dispatch('player/shuffleNow', {
          tracks: this.playableTracks,
        })
      },
      async playTrack(track: Track) {
        if (track.id === this.playingTrackId) {
          return this.$store.dispatch('player/playPause')
        }
        const index = this.playableTracks.findIndex((x: any) => x.id === track.id)
        return this.$store.dispatch('player/playTrackList', {
          index,
          tracks: this.playableTracks,
        })
      },
      pathPush(id: string) {
        const pathID = this.pathID === '' ? id : [...this.pathID.split('/'), id].join('/')
        this.$router.push({ path: `/files/${pathID}` })
      },
      pathPop() {
        const pathID = this.pathID.split('/').slice(0, -1).join('/')
        this.$router.push({ path: `/files/${pathID}` })
      },
      pathSlice(idx: number) {
        const pathID = this.pathID.split('/').slice(0, idx).join('/')
        this.$router.push({ path: `/files/${pathID}` })
      }
    }

  })
