
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import { usePlaylistStore } from '@/library/playlist/store'
  import { useMainStore } from '@/shared/store'
  import { Playlist, Track } from '@/shared/api'

  export default defineComponent({
    props: {
      visible: { type: Boolean, required: true },
      tracks: { type: Array<Track>, default: () => [] },
      new: { type: Boolean }
    },
    setup() {
      const playlistStore = usePlaylistStore()
      const { playlists } = storeToRefs(playlistStore)
      return {
        mainStore: useMainStore(),
        playlistStore,
        playlists,
      }
    },
    data() {
      return {
        type: this.new ? 'new' : 'select',
        item: {} as Playlist,
      }
    },
    async created() {
      await this.playlistStore.load()
    },
    methods: {
      cancel() {
        this.$emit('update:visible', false)
        this.item = {} as Playlist
        this.type = this.new ? 'new' : 'select'
      },
      async save(playlistId = '') {
        if (playlistId) {
          if (!this.tracks) {
            this.mainStore.addNotification({ message: 'Nothing to add', title: 'Playlist' })
            return
          }
          await this.playlistStore.addTracks(playlistId, this.tracks.map(t => t.id))
          this.mainStore.addNotification({ message: `Successfully added ${this.tracks.length} ${this.tracks?.length > 1 ? 'tracks' : 'track'} to playlist`, title: 'Playlist' })
          this.cancel()
        } else {
          if (this.item.name.length === 0) {
            this.mainStore.addNotification({ message: 'Name can not be empty', title: 'Playlist' })
            return
          }
          await this.playlistStore.create({ name: this.item.name, ...(this.tracks ? { songId: this.tracks.map(t => t.id) } : {}) })
          const addedMessage = this.tracks ? ` and added ${this.tracks.length} ${this.tracks?.length > 1 ? 'tracks' : 'track'} to playlist` : ''
          this.mainStore.addNotification({ message: `Successfully created ${addedMessage}`, title: 'Playlist' })
          this.cancel()
        }
      },
    }
  })
