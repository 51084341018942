
  import { defineComponent } from 'vue'
  import BaseTable from '@/shared/components/BaseTable.vue'
  import BaseTableHead from '@/shared/components/BaseTableHead.vue'
  import CellTrackNumber from '@/shared/components/track/CellTrackNumber.vue'
  import CellDuration from '@/shared/components/track/CellDuration.vue'
  import CellAlbum from '@/shared/components/track/CellAlbum.vue'
  import CellArtist from '@/shared/components/track/CellArtist.vue'
  import CellTitle from '@/shared/components/track/CellTitle.vue'
  import CellActions from '@/shared/components/track/CellActions.vue'

  export default defineComponent({
    components: {
      CellActions,
      CellTitle,
      CellArtist,
      CellAlbum,
      CellDuration,
      CellTrackNumber,
      BaseTableHead,
      BaseTable,
    },
    computed: {
      isPlaying() {
        return this.$store.getters['player/isPlaying']
      },
      tracks() {
        return this.$store.state.player.queue
      },
      queueIndex() {
        return this.$store.state.player.queueIndex
      },
    },
    methods: {
      play(index: number) {
        if (index === this.queueIndex) {
          return this.$store.dispatch('player/playPause')
        }
        return this.$store.dispatch('player/playTrackListIndex', { index })
      },
      dragstart(id: string, event: any) {
        event.dataTransfer.setData('application/x-track-id', id)
      },
      remove(idx: number) {
        return this.$store.commit('player/removeFromQueue', idx)
      },
      clear() {
        return this.$store.commit('player/clearQueue')
      },
      shuffle() {
        return this.$store.commit('player/shuffleQueue')
      }
    }
  })
