
  import axios from 'axios'
  import { defineComponent } from 'vue'
  import Logo from './Logo.vue'

  export default defineComponent({
    components: {
      Logo,
    },
    props: {
      visible: { type: Boolean, required: true },
    },
    data() {
      return {
        version: process?.env?.VUE_APP_VERSION,
        newVersion: null,
      }
    },
    computed: {
      build: () => process.env.VUE_APP_BUILD,
      buildDate: () => process.env.VUE_APP_BUILD_DATE,
    },
    created() {
      axios.get('https://raw.githubusercontent.com/archekb/xonic-ui/main/package.json').then(responce => { this.newVersion = responce.data?.version })
    },
  })
