
  import { computed, defineComponent } from 'vue'
  import PlaylistModal from '@/library/playlist/PlaylistModal.vue'
  import { usePlaylistStore } from '@/library/playlist/store'

  export default defineComponent({
    components: {
      PlaylistModal
    },
    setup() {
      const store = usePlaylistStore()
      const playlists = computed(() => {
        return store.playlists?.slice(0, 10)
      })
      return {
        playlists,
        addTracks: store.addTracks
      }
    },
    data() {
      return {
        playlistName: '',
        showAddModal: false,
      }
    },
    methods: {
      async onDrop(playlistId: string, event: any) {
        event.preventDefault()
        const trackId = event.dataTransfer.getData('application/x-track-id')
        if (trackId) {
          return this.addTracks(playlistId, [trackId])
        }
        const albumId = event.dataTransfer.getData('application/x-album-id')
        if (albumId) {
          const album = await this.$api.getAlbumDetails(albumId)
          if (!album?.tracks) return
          return this.addTracks(
            playlistId, album.tracks.map(item => item.id)
          )
        }
      },
      onDragover(event: DragEvent) {
        if (
          event.dataTransfer?.types.includes('application/x-track-id') ||
          event.dataTransfer?.types.includes('application/x-album-id')
        ) {
          event.dataTransfer.dropEffect = 'copy'
          event.preventDefault()
        }
      },
    }
  })
