
  import { defineComponent } from 'vue'
  import Sorter from './Sorter.vue'
  import { useSettingsStore, enums } from '@/settings/store'
  import { BRow, BCol, BFormSelect, BFormInput } from 'bootstrap-vue'
  import { isEqual } from 'lodash-es'
  import { Chrome } from 'vue-color'

  export default defineComponent({
    components: {
      BRow,
      BCol,
      Sorter,
      BFormSelect,
      BFormInput,
      'chrome-picker': Chrome,
    },
    setup() {
      return { settings: useSettingsStore() }
    },
    data() {
      return {
        section: {
          ui: true,
          player: true,
        } as any,
        enums,
        isEqual
      }
    },
    methods: {
      sectionToggle(id: string) {
        this.section = { ...this.section, [id]: !this.section[id] }
      },
      sectionIsShow(id: string) {
        return this.section[id]
      },
      save(path: string) {
        return (value: any) => {
          console.log(value)
          this.settings.set(path, value)
        }
      }
    }
  })
