
  import { defineComponent } from 'vue'
  import ProgressBar from '@/player/ProgressBar.vue'
  import { useFavouriteStore } from '@/library/favourite/store'
  import { BPopover } from 'bootstrap-vue'

  export default defineComponent({
    components: {
      BPopover,
      ProgressBar,
    },
    props: {
      share: { type: Boolean },
    },
    setup() {
      return {
        favouriteStore: useFavouriteStore()
      }
    },
    computed: {
      visible() {
        return this.$store.state.player.queue.length > 0
      },
      isPlaying() {
        return this.$store.state.player.isPlaying
      },
      volume() {
        return this.$store.state.player.volume
      },
      isMuted() {
        return this.$store.state.player.volume <= 0.0
      },
      repeatActive(): boolean {
        return this.$store.state.player.repeat
      },
      shuffleActive(): boolean {
        return this.$store.state.player.shuffle
      },
      playbackRate(): number {
        return this.$store.getters['player/playbackRate']
      },
      isFavourite(): boolean {
        return this.track && !!this.favouriteStore.tracks[this.track.id]
      },
      track() {
        return this.$store.getters['player/track']
      },
      streamTitle() {
        return this.$store.state.streamTitle
      },
      documentTitle(): string {
        return [
          this.streamTitle || this.track?.title,
          this.track?.artist || this.track?.album,
          'xonic-ui'
        ].filter(x => !!x).join(' • ')
      }
    },
    watch: {
      documentTitle: {
        immediate: true,
        handler(value: string) {
          document.title = value
        }
      }
    },
    methods: {
      playPause() {
        return this.$store.dispatch('player/playPause')
      },
      next() {
        return this.$store.dispatch('player/next')
      },
      previous() {
        return this.$store.dispatch('player/previous')
      },
      setVolume(volume: any) {
        return this.$store.dispatch('player/setVolume', parseFloat(volume))
      },
      setPlaybackRate(value: number) {
        return this.$store.dispatch('player/setPlaybackRate', value)
      },
      toggleRepeat() {
        return this.$store.dispatch('player/toggleRepeat')
      },
      toggleShuffle() {
        return this.$store.dispatch('player/toggleShuffle')
      },
      toggleFavourite() {
        return this.favouriteStore.toggle('track', this.track.id)
      },
    }
  })
