
  import { defineComponent } from 'vue'
  import { storeToRefs } from 'pinia'
  import { usePodcastStore } from '@/library/podcast/store'
  import { orderBy } from 'lodash-es'
  import AddPodcastModal from '@/library/podcast/AddPodcastModal.vue'
  import { UnsupportedOperationError } from '@/shared/api'

  export default defineComponent({
    components: {
      AddPodcastModal,
    },
    props: {
      sort: { type: String, default: null },
    },
    setup() {
      const podcastStore = usePodcastStore()
      const { podcasts, supported } = storeToRefs(podcastStore)
      return { podcasts, supported, podcastStore }
    },
    data() {
      return {
        showAddModal: false,
        unsupported: false,
      }
    },
    computed: {
      sortedItems(): any[] {
        return this.sort === 'a-z'
          ? orderBy(this.podcasts, 'name')
          : orderBy(this.podcasts, 'updatedAt', 'desc')
      },
    },
    async created() {
      try {
        this.podcastStore.load()
      } catch (err) {
        if (err instanceof UnsupportedOperationError) {
          this.unsupported = true
          return
        }
        throw err
      }
    },
    methods: {
      async refresh() {
        try {
          await this.podcastStore.refresh()
        } catch (err) {
          console.log(err)
        }
      },
      async add(url: string) {
        try {
          await this.podcastStore.add(url)
        } catch (err) {
          console.log(err)
        }
      },
    }
  })
